<template>
  <b-col class="col-12 col-md-8 col-lg-6 pt-5 px-3 p_b__3">
    <h4 class="mb-4">
      Support
    </h4>
  </b-col>
</template>

<script>
export default {};
</script>

<style scoped></style>
